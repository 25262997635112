import * as React from 'react';
import { Link } from 'gatsby';
import SEO from '../Components/seo';
import Layout from '../Components/Layout/Layout';

// markup
const NotFoundPage = () => (
  <>
    <SEO title="404" />
    <Layout page="404">
      <main id="main" className="mt-4 mb-4">
        <center>
          <h1>Page not found</h1>
          <p>
            Sorry
            {' '}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>
            {' '}
            we couldn’t find what you were looking for.
            <br />
            <Link to="/">Go home</Link>
            .
          </p>
        </center>
      </main>
    </Layout>
  </>

);

export default NotFoundPage;
